.square {
	--box-shadow-color: var(--glow-color);
	align-items: center;
	background-color: transparent;
	border: 2px solid var(--theme-color);
	box-shadow: 0 0 0.625vh 0.625vh var(--box-shadow-color), 0 0 0.625vh 0.625vh var(--box-shadow-color) inset;
	box-sizing: border-box;
	color: white;
	cursor: pointer;
	display: flex;
	filter: hue-rotate(0deg);
	font-size: 7vh;
	height: 9vh;
	justify-content: center;
	position: relative;
	transform-origin: center;
	width: 9vh;
}

.square:not(.prefilled) {
	text-shadow: 0 0 0.2em #ffffff80;
}

:global(#root) > div:not(:global(.isTouch)) .square:hover {
	background-color: #ffffff33;
}

.square.selected {
	background-color: #ffffff33;
	border-color: white;
	border-width: 4px;
	z-index: 101;
}

.thirdColumn {
	margin-right: 10px;
}

.thirdRow {
	margin-bottom: 10px;
}

.prefilled {
	color: var(--glow-color);
	pointer-events: none;
}

.square.bad {
	color: #ff000080;
	text-shadow: 0 0 0.2em #ff0000dd;
}

.square.bad:not(.prefilled) {
	background-color: #ff000033;
	color: red;
}
