.menuItem {
	align-items: baseline;
	display: flex;
	justify-content: space-between;
}

.name {
	font-weight: bold;
	margin-right: 20px;
}

.option {
	cursor: pointer;
	padding: 0 7px;
	transition: color 0.5s linear;
}

.option:hover {
	color: white;
}

.active {
	color: white;
	pointer-events: none;
	text-shadow: 0 0 0.2em #ffffff80;
}
