.settings {
	bottom: 10px;
	color: var(--theme-color);
	right: 10px;
	position: absolute;
}

.gearButton {
	cursor: pointer;
	display: block;
	font-size: 6vh;
	transition: color 0.5s linear;
}

:global(#root) > div:not(:global(.isTouch)) .gearButton:hover {
	color: white;
}

.menu {
	background-color: #0007;
	border: 1px solid var(--theme-color);
	bottom: 6vh;
	font-size: 20px;
	padding: 10px;
	position: absolute;
	right: 6vh;
}

.buttons {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 10px;
}
