.header {
	color: white;
	position: absolute;
	right: 2.5vh;
	text-align: right;
	top: 2.5vh;
}

.title {
	font-family: "Erica One";
	font-size: 2em;
}

@media (min-height: 768px) {
	.title {
		font-size: 3em;
	}
}
