body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: black;
	user-select: none;
}

:root {
	--blue-glow: rgba(0, 203, 255, 0.502);
	--blue-theme: #00ccff;

	--green-glow: rgba(0, 255, 0, 0.502);
	--green-theme: #00ff00;

	--pink-glow: rgba(253, 73, 229, 0.502);
	--pink-theme: #fd82ed;

	--yellow-glow: rgba(255, 255, 0, 0.502);
	--yellow-theme: #ffff00;
}

button {
	all: unset;
	-webkit-text-fill-color: initial;
}

button:focus {
	outline: none;
}
