.app {
	align-items: center;
	background: linear-gradient(180deg, #0c0c0c 0%, #2b2b2b 64%, #0c0c0c 100%);
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow: hidden;
	padding: 2.5vh;
	position: relative;
	width: 100vw;
}

.layout {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	justify-content: space-around;
	width: 100%;
}

.footer {
	bottom: 0;
	display: flex;
	justify-content: flex-end;
	left: 0;
	position: absolute;
	width: 100%;
}
