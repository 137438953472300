.portraitWarning {
	align-items: center;
	background-color: #000d;
	bottom: 0;
	color: white;
	display: none;
	left: 0;
	justify-content: center;
	padding: 70px;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	z-index: 999;
}

@media (max-aspect-ratio: 1/1) {
	.portraitWarning {
		display: flex;
	}
}
