.container {
	font-size: 0.8em;
	text-align: right;
}

.container a {
	color: var(--theme-color);
	font-weight: bold;
	text-decoration: none;
	transition: color 0.5s linear;
}

.container a:hover {
	color: white;
}

@media (min-height: 768px) {
	.container {
		font-size: 1em;
	}
}
