.number {
	align-items: center;
	border: 1px solid var(--theme-color);
	border-radius: 72px;
	color: var(--theme-color);
	cursor: pointer;
	display: flex;
	font-size: 2em;
	height: 9vh;
	justify-content: center;
	min-height: 50px;
	min-width: 50px;
	opacity: inherit;
	transition: background-color 0.5s linear, border-color 0.5s linear, color 0.5s linear;
	width: 9vh;
}

:global(#root) > div:not(:global(.isTouch)) .number:hover {
	background-color: var(--theme-color);
	color: black;
}

.number:last-of-type {
	grid-column: 2;
}
