.grid {
	-webkit-backface-visibility: hidden;
	display: grid;
	flex-wrap: wrap;
	grid-template-columns: repeat(9, 1fr);
	grid-template-rows: repeat(9, 1fr);
	height: 86vh;
	justify-content: center;
	perspective: 500px;
	place-items: center;
	width: 86vh;
}

.container {
	perspective: 1000px;
}

@media (min-width: 1024px) {
	.container {
		padding: 40px;
	}
}
