.picker {
	display: grid;
	gap: 10px;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr);
	place-items: center;
	place-self: center;
	transition: opacity 0.25s linear;
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
}

@media (max-width: 768px) {
	.picker {
		align-self: flex-end;
	}
}
